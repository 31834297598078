import {
  CreateAccountRequest,
  CreateAccountResponse,
  LaunchSubscribeRequest,
  GetAccountResponse,
} from "types/dto";
import { baseApi } from "../base.api";

export const clientsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    account: builder.query<GetAccountResponse["data"], undefined>({
      query: () => ({
        url: `account`,
      }),
      transformResponse: (response: GetAccountResponse) => response.data,
    }),
    createAccount: builder.mutation<
      CreateAccountResponse["data"],
      CreateAccountRequest
    >({
      query: (body) => ({
        url: "accounts",
        method: "POST",
        body,
      }),
      transformResponse: (response: CreateAccountResponse) => response.data,
    }),
    subscribe: builder.mutation<void, LaunchSubscribeRequest>({
      query: (body) => ({
        url: "subscribers",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useAccountQuery,
  useCreateAccountMutation,
  useSubscribeMutation,
} = clientsApi;
