import { useEffect } from "react";
import { MixActions, MixPanel } from "lib/mixpanel";
import { Dict } from "mixpanel-browser";

export const useMixpanelTrack = (action: MixActions, properties?: Dict) => {
  useEffect(() => {
    MixPanel.track(action, properties);
  }, []);
};

export const useLazyMixpanelTrack = () => {
  return (action: MixActions, properties?: Dict) => {
    if (process.env.NODE_ENV === "development") {
      console.log("MixPanel.track('%s')", action, properties);
    }
    MixPanel.track(action, properties);
  };
};
