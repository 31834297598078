import { Header, Text } from "components/shared/typography";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";

type OwnershipSliderProps = {
  value: number;
  max?: number;
  min?: number;
  step?: number;
  onChange: (value: number) => void;
};

export const OwnershipSlider = ({
  value,
  max = 1,
  min = 0.2,
  step = 0.05,
  onChange,
}: OwnershipSliderProps) => {
  const handleChange = (value: number) => {
    if (value < min) value = min;
    if (value > max) value = max;
    onChange(value);
  };
  return (
    <div className="flex flex-row justify-around items-center select-none">
      <Button icon={MinusIcon} onClick={() => handleChange(value - step)} />
      <div>
        <Header>{Math.round(value * 100)}%</Header>
        <Text textSize="text-sm" textColor="text-gray-400">
          Ownership
        </Text>
      </div>
      <Button icon={PlusIcon} onClick={() => handleChange(value + step)} />
    </div>
  );
};

type ButtonProps = {
  icon: any;
  onClick?: () => void;
};

const Button = ({ icon: Icon, ...otherProps }: ButtonProps) => (
  <div className="bg-salmon-600 p-2 rounded-lg cursor-pointer" {...otherProps}>
    <Icon className="text-white w-6 h-6" />
  </div>
);
