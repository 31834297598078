import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { API } from "lib/API";
import {
  BeginSignupRequest,
  BeginSignupResponse,
  CompleteSignupRequest,
  CompleteSignupResponse,
  ErrorResponse,
  LoginRequest,
  LoginResponse,
} from "types";

export const login = createAsyncThunk<
  LoginResponse,
  LoginRequest,
  {
    rejectValue: ErrorResponse;
  }
>("accounts/login", async (request, { rejectWithValue }) => {
  try {
    const { token } = await API.POST<LoginRequest, LoginResponse>(
      "/signin",
      request
    );
    return { token };
  } catch (err) {
    const error = err as AxiosError<ErrorResponse>;
    if (!error.response) throw err;
    return rejectWithValue(error.response.data);
  }
});

export const beginSignup = createAsyncThunk<
  BeginSignupResponse,
  BeginSignupRequest,
  {
    rejectValue: ErrorResponse;
  }
>("accounts/beginSignup", async (request, { rejectWithValue }) => {
  try {
    await API.POST<BeginSignupRequest, BeginSignupResponse>("/signup", request);
  } catch (err) {
    const error = err as AxiosError<ErrorResponse>;
    if (!error.response) throw err;
    return rejectWithValue(error.response.data);
  }
});

export const completeSignup = createAsyncThunk<
  CompleteSignupResponse,
  CompleteSignupRequest,
  {
    rejectValue: ErrorResponse;
  }
>("accounts/completeSignup", async (request, { rejectWithValue }) => {
  try {
    return await API.POST<CompleteSignupRequest, CompleteSignupResponse>(
      "/signup/callback",
      request,
      {
        headers: { Authorization: request.token },
      }
    );
  } catch (err) {
    const error = err as AxiosError<ErrorResponse>;
    if (!error.response) throw err;
    return rejectWithValue(error.response.data);
  }
});
