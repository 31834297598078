import mixpanel, { Dict } from "mixpanel-browser";
import { MixActions } from "lib/mixpanel/MixActions";

class MixPanel {
  private readonly _token = process.env.REACT_APP_MIXPANEL_TOKEN;

  constructor() {
    if (!this._token) return;
    const options = {
      api_host: "https://api-eu.mixpanel.com",
      debug: process.env.NODE_ENV === "development",
    };
    mixpanel.init(this._token, options);
  }

  get initialised(): boolean {
    return Boolean(this._token);
  }

  track(action: MixActions, properties: Dict = {}) {
    if (!this.initialised) return;
    mixpanel.track(action, properties);
  }
}

export default new MixPanel();
