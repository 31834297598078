import { Header, Text } from "components/shared/typography";
import { DarkCard } from "components/shared/cards";
import { TCalculatorBedroom } from "types";
import {
  calculateCompoundedPrice,
  calculateInterestPaid,
  calculateMortgagePayment,
} from "lib/utils/mortgage";
import memojiPartyFriendsUrl from "assets/images/memojis/memoji_party_friends.png";
import styled from "styled-components";

const MemojiImage = styled.img`
  position: absolute;
  left: -2rem;
  top: -4.5rem;
  width: 13rem;

  @media screen and (min-width: 640px) {
    width: 15rem;
    top: -5rem;
  }
`;

type CalculatorOutcomeProps = {
  propertyPrice: number;
  bedrooms: TCalculatorBedroom[];
  years?: number;
};

export const CalculatorOutcome = ({
  propertyPrice,
  bedrooms,
  years = 3,
}: CalculatorOutcomeProps) => {
  const propertyPriceGrowthRate = 0.07; // 7% per year
  const mortgageDuration = 25; // 25 years
  const mortgageInterestRate = 0.045; // 4.5% per year

  const bedroom = bedrooms[0] || {};
  const bedroomPrice = bedroom.ownership * propertyPrice;
  const bedroomDeposit = bedroom.ownership * bedroom.depositPercentage;
  const mortgageAmount = bedroomPrice - bedroomDeposit;
  const futureBedroomPrice = calculateCompoundedPrice(bedroomPrice, {
    years: years,
    growthRate: propertyPriceGrowthRate,
  });
  const monthlyPayment = calculateMortgagePayment(mortgageAmount, {
    years: mortgageDuration,
    growthRate: mortgageInterestRate,
  });
  const totalPayments = monthlyPayment * (years * 12);
  const totalInterestPaid = calculateInterestPaid(
    mortgageAmount,
    monthlyPayment,
    years,
    {
      years: mortgageDuration,
      growthRate: mortgageInterestRate,
    }
  );
  const profit =
    futureBedroomPrice - bedroomPrice + (totalPayments - totalInterestPaid);

  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
  });

  return (
    <DarkCard
      paddingX="px-12"
      paddingY="py-12"
      className="text-center overflow-visible mt-16"
    >
      <MemojiImage src={memojiPartyFriendsUrl} />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <Text textSize="text-xl">You could be</Text>
          <Text textSize="text-5xl" fontWeight="font-semibold">
            {formatter.format(profit)}
          </Text>
          <Text textSize="text-xl">better off after 3 years</Text>
        </div>
      </div>
      <div className="grid grid-cols-2 grid-flow-row gap-8">
        <div className="flex flex-col">
          <Header>{(propertyPriceGrowthRate * 100).toFixed(1)}%</Header>
          <Text textSize="text-sm" textColor="text-gray-400">
            Property price
            <br />
            annual growth
          </Text>
        </div>
        <div className="flex flex-col">
          <Header>{formatter.format(futureBedroomPrice - bedroomPrice)}</Header>
          <Text textSize="text-sm" textColor="text-gray-400">
            Bedroom price
            <br />
            increase
          </Text>
        </div>
        <div className="flex flex-col">
          <Header>{(mortgageInterestRate * 100).toFixed(1)}%</Header>
          <Text textSize="text-sm" textColor="text-gray-400">
            Mortgage interest
            <br />
            rate
          </Text>
        </div>
        <div className="flex flex-col">
          <Header>{formatter.format(totalPayments - totalInterestPaid)}</Header>
          <Text textSize="text-sm" textColor="text-gray-400">
            Mortgage principal
            <br />
            paid-off
          </Text>
        </div>
      </div>
      <Text textColor="text-gray-400">
        If you stop paying your mortgage, you could lose your home
      </Text>
    </DarkCard>
  );
};
