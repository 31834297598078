import { ReactNode } from "react";
import classNames from "classnames";
import {
  TailwindFontWeight,
  TailwindTextColor,
  TailwindTextSize,
} from "types/tailwind";

type TitleProps = {
  textColor?: TailwindTextColor;
  fontWeight?: TailwindFontWeight;
  textSize?: TailwindTextSize;
  id?: string;
  className?: string;
  children?: ReactNode;
};

export const CardTitle = ({
  textColor = "text-current",
  fontWeight = "font-bold",
  textSize = "text-3xl",
  id,
  className,
  children,
}: TitleProps) => {
  const classes = classNames(textColor, fontWeight, textSize, className);
  return (
    <h1 id={id} className={classes}>
      {children}
    </h1>
  );
};
