import clsx from "clsx";
import { ReactNode } from "react";
import styled from "styled-components";
import { TailwindBackgroundColor, TailwindMaxWidth } from "types";
import {
  HeroTitle,
  LaunchForm,
  MarketingHeader,
  Calculator,
  PrimaryButton,
} from "components/shared";
import { Footer } from "components/shared/layouts/Footer/Footer";
import { useMixpanelTrack } from "hooks";
import { MixActions } from "lib/mixpanel";
import { RightmoveForm } from "components/shared/features/rightmove";
import { useSelector } from "store";
import { selectRightmoveProperty } from "store/reducers";

const Disclaimer = styled.div`
  font-size: 0.875rem;
  color: white;
  opacity: 0.7;
  margin-top: -0.25rem;
`;

type ContainerProps = {
  backgroundColor?: TailwindBackgroundColor;
  maxWidth?: TailwindMaxWidth;
  className?: string;
  children?: ReactNode | ReactNode[];
};

export const Container = ({
  maxWidth = "max-w-6xl",
  backgroundColor,
  className,
  ...otherProps
}: ContainerProps) => {
  return (
    <div
      className={clsx(
        "mx-auto px-4 sm:px-6 lg:px-8",
        backgroundColor,
        maxWidth,
        className
      )}
      {...otherProps}
    />
  );
};

export const MarketingPage = () => {
  useMixpanelTrack(MixActions.VisitMarketingPage);
  const property = useSelector(selectRightmoveProperty);
  return (
    <div className="bg-persianGreen-600">
      <Container maxWidth="max-w-7xl">
        <MarketingHeader />
      </Container>
      <Container maxWidth="max-w-7xl" className="py-10 lg:py-16">
        <HeroTitle />
        <div className="flex flex-col lg:flex-row mt-10 gap-x-6 gap-y-4">
          <a
            href="https://7erizh7jrxs.typeform.com/to/keizlscc"
            target="_blank"
            className="relative"
          >
            <PrimaryButton
              paddingY="py-4"
              paddingX="px-8"
              trackAction={MixActions.ClickHeroTakeSurvey}
              className="flex-col"
            >
              <div>Take 60 sec survey</div>
              <Disclaimer className="font-xs">
                Chance to talk with the founder
              </Disclaimer>
            </PrimaryButton>
            <div className="absolute -bottom-1/2 left-0 w-full flex justify-center">
              <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="h-[1.25rem] max-w-[15rem] fill-salmon-600"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z" />
              </svg>
            </div>
          </a>
        </div>
      </Container>
      <Container>
        <div
          id="rightmove"
          className={clsx(
            "mt-32 sm:mt-44",
            property && "-mb-[20rem] sm:-mb-[14rem]"
          )}
        >
          <RightmoveForm />
        </div>
      </Container>
      {property && (
        <div className="bg-slate-100">
          <Container className="py-[13rem] pt-[25rem] sm:py-16 sm:pt-[20rem]">
            <Calculator property={property} />
          </Container>
        </div>
      )}
      <Container className={property ? "" : "mt-40"}>
        <LaunchForm />
      </Container>
      <Container>
        <Footer />
      </Container>
    </div>
  );
};
