import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import classNames from "classnames";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import { Text } from "components/shared/typography/Text/Text";

dayjs.extend(utc);
dayjs.extend(tz);

type ClockProps = {
  dateFormat?: string;
  timeFormat?: string;
  timezone?: string;
  className?: string;
};

export const Clock = ({
  dateFormat = "ddd, D MMM",
  timeFormat = "h:mm A",
  timezone,
  className,
}: ClockProps) => {
  const [now, setNow] = useState<Dayjs>(dayjs.utc());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setNow(dayjs.utc());
    }, 1000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  const renderTimestamp = (format: string): string => {
    if (timezone) {
      return now.tz(timezone).format(format);
    }
    return now.format(format);
  };

  return (
    <Text
      textColor="text-current"
      className={classNames(className, "text-right")}
    >
      {renderTimestamp(dateFormat)}
      <br />
      {renderTimestamp(timeFormat)}
    </Text>
  );
};
