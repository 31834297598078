export enum MixActions {
  VisitMarketingPage = "Visit Marketing Page",
  VisitTermsConditionsPage = "Visit Terms & Conditions Page",
  VisitPrivacyPolicyPage = "Visit Privacy Policy Page",

  ClickNavbarGetStarted = "Click NavBar Get Started",
  ClickNavbarLogin = "Click NavBar Login",
  ClickHeroGetEarlyAccess = "Click Hero Get Early Access",
  ClickHeroTakeSurvey = "Click Hero Take Survey",
  ClickHeroFindHome = "Click Hero Find Home",
  ClickHeroFindTenant = "Click Hero Find Tenant",

  LaunchSubscribe = "Launch subscribe",
  GetEarlyAccess = "Get early access",
  FindRightmoveProperty = "Find Rightmove property",
  UsePropertyScrape = "Use Property Scrape",
  OnBoardingAboutYouCompleted = "OnBoarding About You Completed",
}
