import * as jwt from "jose";
import Cookies from "universal-cookie";
import { CookieSetOptions } from "universal-cookie/cjs/types";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { completeSignup, login } from "store/reducers/accounts/accounts.thunks";
import { AuthSession, LoginResponse } from "types";

const hydrateSession = (): AuthSession | undefined => {
  const cookies = new Cookies();
  const token = cookies.get("session");
  if (!token) {
    return;
  }
  try {
    return jwt.decodeJwt(token) as AuthSession;
  } catch (err) {
    console.error("failed to decode auth session JWT token", err);
    return undefined;
  }
};

const sessionExpiry = (session: AuthSession): number => {
  return session.exp * 1000;
};

const getCookieOptions = (session: AuthSession): CookieSetOptions => {
  return {
    expires: new Date(sessionExpiry(session)),
  };
};

const receiveAuthToken = (
  state: Draft<AccountsReducerState>,
  token: string
) => {
  const cookies = new Cookies();
  const session: AuthSession = jwt.decodeJwt(token) as AuthSession;
  const options = getCookieOptions(session);
  cookies.set("session", token, options);
  state.session = session;
};

const handleLogin = (
  state: Draft<AccountsReducerState>,
  action: PayloadAction<LoginResponse>
) => {
  const { token } = action.payload;
  receiveAuthToken(state, token);
};

export interface AccountsReducerState {
  session?: AuthSession;
}

const initialState: AccountsReducerState = {
  session: hydrateSession(),
};

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      receiveAuthToken(state, action.payload);
    },
    logout: (state, _: PayloadAction) => {
      if (state.session) {
        const cookies = new Cookies();
        cookies.remove("session", getCookieOptions(state.session));
        state.session = undefined;
      }
      window.location.href = "/login";
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, handleLogin)
      .addCase(completeSignup.fulfilled, handleLogin);
  },
});

export const { setAuthToken, logout } = accountsSlice.actions;

export const accountsReducer = accountsSlice.reducer;
