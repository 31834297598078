import { Navigate, Route, Routes } from "react-router-dom";
import {
  BeginSignUpPage,
  MarketingPage,
  PrivacyPolicyPage,
  BudgetPage,
  OnBoardingPage,
  AboutYouPage,
  TimeframePage,
  LocationPage,
  SearchCharacteristicsPage,
} from "pages";
import { AuthLayout, OnBoardingLayout } from "pages/layouts";

export const AppRouter = () => {
  return (
    <Routes>
      {/*<Route element={<AuthLayout />}>*/}
      {/*  <Route path="signup" element={<BeginSignUpPage />} />*/}
      {/*  <Route path="onboarding" element={<OnBoardingLayout />}>*/}
      {/*    <Route path="about-you" element={<AboutYouPage />} />*/}
      {/*    <Route path="timeframe" element={<TimeframePage />} />*/}
      {/*    <Route path="location" element={<LocationPage />} />*/}
      {/*    <Route path="budget" element={<BudgetPage />} />*/}
      {/*    <Route*/}
      {/*      path="characteristics"*/}
      {/*      element={<SearchCharacteristicsPage />}*/}
      {/*    />*/}
      {/*    <Route index element={<OnBoardingPage />} />*/}
      {/*  </Route>*/}
      {/*</Route>*/}
      <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="how-it-works" element={<MarketingPage />} />
      <Route path="launch" element={<MarketingPage />} />
      <Route index element={<MarketingPage />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  );
};
