import { ReactNode } from "react";
import styled from "styled-components";
import clsx from "clsx";
import {
  TailwindBackgroundColor,
  TailwindMediaQueries,
  TailwindPadding,
  TailwindPaddingX,
  TailwindPaddingY,
} from "types";

const StyledCard = styled.div`
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 20, 0.04);
`;

export type PlainCardProps = {
  backgroundColor?: TailwindBackgroundColor;
  padding?: TailwindPadding;
  paddingX?: TailwindMediaQueries<TailwindPaddingX>;
  paddingY?: TailwindMediaQueries<TailwindPaddingY>;
  children: ReactNode | ReactNode[];
  id?: string;
  className?: string;
  onClick?: () => void;
};

export const PlainCard = ({
  id,
  padding,
  paddingX = "px-6 sm:px-12",
  paddingY = "py-12 sm:py-24",
  backgroundColor = "bg-white",
  children,
  className = "",
  ...otherProps
}: PlainCardProps) => {
  return (
    <StyledCard
      {...otherProps}
      id={id}
      className={clsx(
        "relative isolate flex flex-col gap-10 overflow-hidden rounded-3xl",
        padding,
        paddingX,
        paddingY,
        backgroundColor,
        className
      )}
    >
      {children}
    </StyledCard>
  );
};
