import { combineReducers } from "redux";
import {
  popupFlagReducer,
  filesReducer,
  accountsReducer,
  propertiesReducer,
} from "store/reducers";
import { baseApi } from "store/base.api";

const appReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  accounts: accountsReducer,
  popupFlag: popupFlagReducer,
  files: filesReducer,
  properties: propertiesReducer,
});

export type RootStore = ReturnType<typeof appReducer>;

const reducer = (state: RootStore | undefined, action: any) => {
  return appReducer(state, action);
};

export default reducer;
