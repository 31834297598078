import { useEffect, useState } from "react";
import Flicking, { ViewportSlot, Plugin } from "@egjs/react-flicking";
import { AutoPlay, Pagination } from "@egjs/flicking-plugins";
import clsx from "clsx";
import styled from "styled-components";
import { colors } from "lib/colors";
import { TProperty } from "types";
import { PropertyGalleryImage } from "components/shared/features/properties/PropertyGalleryImage";
import { Text } from "components/shared/typography";
import { PlainCard } from "components/shared/cards";

const Gallery = styled(PlainCard)`
  position: relative;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .flicking-pagination {
    display: flex;
    top: 0;
    padding: 1.5rem;
    justify-content: space-between;

    & .flicking-pagination-bullet {
      flex: 1;
      border-radius: 0.25rem;
      background: ${colors.slate["100"]};
      opacity: 0.5;

      &.flicking-pagination-bullet-active {
        opacity: 1;
      }
    }
  }

  @media screen and (min-width: 640px) {
    margin: -6rem -3.5rem -6rem 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
`;

const Description = styled.div`
  z-index: 10;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  right: 1.5rem;
`;

type PropertyGalleryProps = {
  property: TProperty;
  className?: string;
};

export const PropertyGallery = ({
  property,
  className,
}: PropertyGalleryProps) => {
  const { propertyType, images, floorPlanImages, address, postcode } = property;
  const [plugins, setPlugins] = useState<Plugin[]>([]);

  useEffect(() => {
    setPlugins([
      new Pagination({ type: "bullet" }),
      new AutoPlay({ duration: 3000, direction: "NEXT", stopOnHover: true }),
    ]);
  }, []);

  return (
    <Gallery paddingY="" paddingX="" className={clsx("relative", className)}>
      <Flicking useFindDOMNode circular align="prev" plugins={plugins}>
        {[...images, ...floorPlanImages].map((imageUrl) => (
          <PropertyGalleryImage
            key={imageUrl}
            imageUrl={imageUrl}
            className="panel"
          />
        ))}
        <ViewportSlot>
          <div className="flicking-pagination" />
        </ViewportSlot>
      </Flicking>
      <Description className="text-white">
        <Text className="text-lg font-medium capitalize">{propertyType}</Text>
        <Text>
          {address}, {postcode}
        </Text>
      </Description>
    </Gallery>
  );
};
