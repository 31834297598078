import { createSlice } from "@reduxjs/toolkit";
import { rightmoveApi } from "../../apis";
import { TProperty } from "types";

export interface PropertiesReducerState {
  rightmoveProperty?: TProperty;
}

const initialState: PropertiesReducerState = {
  // rightmoveProperty: {
  //   id: "1282795b-0865-4bd9-acf8-b8204c72f3bc",
  //   address: "Brunel Road, Maidenhead, Berkshire",
  //   postcode: "SL6 7XK",
  //   propertyType: "maisonette",
  //   totalPrice: 260000,
  //   bedrooms: 2,
  //   bathrooms: 2,
  //   images: [
  //     "https://media.rightmove.co.uk/57k/56124/130698431/56124_MAS220538_IMG_00_0000.jpeg",
  //     "https://media.rightmove.co.uk/57k/56124/130698431/56124_MAS220538_IMG_01_0000.jpeg",
  //     "https://media.rightmove.co.uk/57k/56124/130698431/56124_MAS220538_IMG_02_0000.jpeg",
  //     "https://media.rightmove.co.uk/57k/56124/130698431/56124_MAS220538_IMG_03_0000.jpeg",
  //     "https://media.rightmove.co.uk/57k/56124/130698431/56124_MAS220538_IMG_04_0000.jpeg",
  //     "https://media.rightmove.co.uk/57k/56124/130698431/56124_MAS220538_IMG_05_0000.jpeg",
  //     "https://media.rightmove.co.uk/57k/56124/130698431/56124_MAS220538_IMG_06_0000.jpeg",
  //   ],
  //   floorPlanImages: [],
  // },
};

export const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      rightmoveApi.endpoints.findRightmoveProperty.matchFulfilled,
      (state, action) => {
        state.rightmoveProperty = action.payload;
      }
    );
  },
});

export const propertiesReducer = propertiesSlice.reducer;
