import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConfirmicProvider } from "@confirmic/react";
import { BrowserRouter } from "react-router-dom";
import store from "store";
import { ModalProvider } from "components/modals/ModalProvider/ModalProvider";
import reportWebVitals from "./reportWebVitals";
import { registerServiceWorker } from "serviceWorker";
import { AppRouter } from "routes/AppRouter";
import "@egjs/react-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/flicking-plugins.css";
import "base.scss";

registerServiceWorker();

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfirmicProvider projectId="my-project-id">
        <ModalProvider>
          <AppRouter />
        </ModalProvider>
      </ConfirmicProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
