import styled from "styled-components";
import logo from "assets/images/logo_light.svg";
import { Clock } from "components/shared/generic/Clock/Clock";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const Navbar = () => {
  return (
    <Container>
      <div />
      <img className="mx-auto w-16" src={logo} alt="Housewarming" />
      <Clock />
    </Container>
  );
};
