import { ReactNode } from "react";
import classNames from "classnames";
import {
  TailwindFontWeight,
  TailwindLineHeight,
  TailwindTextColor,
  TailwindTextDecoration,
  TailwindTextSize,
} from "types/tailwind";

type HeadingProps = {
  textColor?: TailwindTextColor;
  fontWeight?: TailwindFontWeight;
  textSize?: TailwindTextSize;
  lineHeight?: TailwindLineHeight;
  decoration?: TailwindTextDecoration;
  children?: ReactNode;
};

export const Subtitle = ({
  textColor = "text-slate-700",
  textSize = "text-2xl",
  fontWeight = "font-semibold",
  decoration = "",
  lineHeight = "",
  children,
}: HeadingProps) => {
  const classes = classNames(
    textColor,
    textSize,
    fontWeight,
    lineHeight,
    decoration
  );
  return <h3 className={classes}>{children}</h3>;
};
