import { ReactNode } from "react";
import classNames from "classnames";
import {
  TailwindFontWeight,
  TailwindTextColor,
  TailwindTextSize,
} from "types/tailwind";

type TitleProps = {
  textColor?: TailwindTextColor;
  fontWeight?: TailwindFontWeight;
  textSize?: TailwindTextSize;
  className?: string;
  children?: ReactNode;
};

export const Title = ({
  textColor = "text-current",
  fontWeight = "font-extrabold",
  textSize = "text-3xl",
  className,
  children,
}: TitleProps) => {
  const classes = classNames(
    "tracking-tight",
    textColor,
    fontWeight,
    textSize,
    className
  );
  return <h1 className={classes}>{children}</h1>;
};
