import clsx from "clsx";
import styled from "styled-components";
import { ReactSVG } from "react-svg";
import { TailwindWidth } from "types";

const StyledReactSVG = styled(ReactSVG)<IconProps>`
  position: relative;
  display: inline-block;
  margin: 0 auto;
  line-height: 1em;
  fill: currentColor;
`;

export type IconProps = {
  src: string;
  width?: TailwindWidth;
  onClick?: () => void;
  className?: string;
};

export const Icon = ({ src, width, className, onClick }: IconProps) => {
  return (
    <StyledReactSVG
      src={src}
      className={clsx(width, className)}
      onClick={onClick}
      wrapper="span"
    />
  );
};
