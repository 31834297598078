import React, { createContext, Fragment, ReactNode, useState } from "react";
import { Transition } from "@headlessui/react";
import styled from "styled-components";
import { Portal } from "@reach/portal";
import { KEY_CODE_ESC, KeyHandler } from "components/shared";
import { Overlay } from "components/shared/generic/Overlay/Overlay";

const ModalWrapper = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
`;

const StyledOverlay = styled(Overlay)`
  z-index: 0;
`;

const Dialog = styled.div<{ zIndex?: number }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: ${({ zIndex = 0 }) => 20 + zIndex};
`;

type ModalProviderProps = {
  children: ReactNode;
};

type ModalContextProperties = {
  openModal: (component: ReactNode) => void;
  closeModal: () => void;
};

export const ModalContext = createContext<ModalContextProperties>({
  openModal: () => {},
  closeModal: () => {},
});

type State = {
  modals: ReactNode[];
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [state, setState] = useState<State>({
    modals: [],
  });

  const openModal = (modal: ReactNode): void => {
    setState((prevState) => {
      return {
        modals: [...prevState.modals, modal],
      };
    });
  };

  const closeModal = (): void => {
    setState((prevState) => {
      return {
        modals: prevState.modals.slice(0, prevState.modals.length - 1),
      };
    });
  };

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      {children}
      {state.modals.length !== 0 && (
        <KeyHandler keyCode={KEY_CODE_ESC} onKeyHandle={closeModal} />
      )}
      {state.modals.map((Modal, index) => {
        return (
          <Portal key={`portal_${index}`}>
            <Transition.Root appear show as={Fragment}>
              <Dialog zIndex={index}>
                <ModalWrapper>
                  <StyledOverlay isDark onClick={closeModal} />
                  {Modal}
                </ModalWrapper>
              </Dialog>
            </Transition.Root>
          </Portal>
        );
      })}
    </ModalContext.Provider>
  );
};
