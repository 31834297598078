import { ReactNode } from "react";
import classNames from "classnames";
import { TailwindFlexDirection, TailwindGap } from "types/tailwind";

type VSpaceProps = {
  gap?: TailwindGap;
  direction?: TailwindFlexDirection;
  children: ReactNode;
};

export const VSpace = ({
  gap = "gap-2",
  direction = "flex-col",
  children,
}: VSpaceProps) => {
  return <div className={classNames("flex", direction, gap)}>{children}</div>;
};
