import { ReactNode } from "react";
import styled from "styled-components";

const Group = styled.div<ButtonGroupProps>`
  ${({ alignRight }) => alignRight && "justify-content: right"};
`;

type ButtonGroupProps = {
  children: ReactNode;
  alignRight: boolean;
};

export const ButtonGroup = ({ children, alignRight }: ButtonGroupProps) => {
  return (
    <Group className="flex gap-2" alignRight={alignRight}>
      {children}
    </Group>
  );
};
