import Confetti from "react-dom-confetti";
import styled from "styled-components";
import { Icon, IconProps } from "components/shared/icons/Icon";
import { useEffect, useState } from "react";

const defaultConfig = {
  angle: 90,
  spread: 160,
  startVelocity: 10,
  elementCount: 70,
  dragFriction: 0.05,
  duration: 2200,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "800px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
};

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledConfetti = styled(Confetti)`
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-top: -0.9375rem;
  margin-left: -0.9375rem;
`;

type IconWithConfettiProps = IconProps & {
  active?: boolean;
  config?: any;
};

export const IconWithConfetti = ({
  active = true,
  config = defaultConfig,
  ...otherProps
}: IconWithConfettiProps) => {
  const [state, setState] = useState<boolean>(false);
  useEffect(() => {
    setState(active || true);
  }, [active]);
  return (
    <Container>
      <StyledConfetti active={state} config={config} />
      <Icon {...otherProps} />
    </Container>
  );
};
