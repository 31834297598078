import { Link } from "react-router-dom";
import logo from "assets/images/logo_light.svg";

export const HousewarmingLogo = () => {
  return (
    <Link to="/" className="-m-1.5 p-1.5">
      <span className="sr-only">Housewarming</span>
      <img className="h-9 w-auto" src={logo} alt="" />
    </Link>
  );
};
