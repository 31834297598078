import styled from "styled-components";
import { HousewarmingLogo } from "components/shared/icons";

const Nav = styled.nav``;

export const MarketingHeader = () => {
  return (
    <header className="pt-10">
      <Nav
        className="mx-auto flex items-center justify-between gap-x-6 lg:max-w-7xl"
        aria-label="Global"
      >
        <div className="flex flex-1 justify-center lg:justify-start">
          <HousewarmingLogo />
        </div>
      </Nav>
    </header>
  );
};
