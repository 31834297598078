import { FormEvent, useState } from "react";
import { useLazyMixpanelTrack } from "hooks";
import { EmailInput } from "components/shared/inputs";
import { PrimaryButton } from "components/shared/buttons";
import { MixActions } from "lib/mixpanel";
import { useSubscribeMutation } from "store/apis";
import { Header } from "components/shared/typography";
import { IconWithConfetti, LoadingIcon } from "components/shared/icons";

type GetStartedFormProps = {
  scrapeId: string;
  propertyPrice: number;
  bedroomCount: number;
};

export const GetStartedForm = ({
  scrapeId,
  propertyPrice,
  bedroomCount,
}: GetStartedFormProps) => {
  const track = useLazyMixpanelTrack();
  const [email, setEmail] = useState<string>("");
  const [subscribe, { isLoading, isSuccess }] = useSubscribeMutation();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    track(MixActions.GetEarlyAccess, {
      email,
      propertyPrice,
      bedroomCount,
      scrapeId,
    });
    subscribe({ email });
  };

  if (isSuccess) {
    return (
      <div className="text-center">
        <IconWithConfetti src="/images/icons/party.svg" width="w-14" />
        <Header>Thank you for your interest!</Header>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 lg:flex-row">
        <label htmlFor="email-address" className="sr-only">
          Email address
        </label>
        <EmailInput
          name="email"
          placeholder="Enter your email"
          className="bg-white border-0"
          textColor="text-white"
          focusBorderColor="focus:border-transparent"
          focusRingColor="focus:ring-transparent"
          onChange={(event) => setEmail(event.target.value)}
        />
        <PrimaryButton
          type="submit"
          borderColor="border-transparent"
          disabledBackground=""
          className="lg:w-min"
          disabled={!email || isLoading}
        >
          {isLoading ? <LoadingIcon /> : "Get early access"}
        </PrimaryButton>
      </div>
      <p className="mt-4 text-sm leading-6 text-center text-gray-400">
        We care about your data. Read our{" "}
        <a href="/privacy-policy" className="font-semibold">
          privacy&nbsp;policy
        </a>
        .
      </p>
    </form>
  );
};
