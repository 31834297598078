import styled from "styled-components";

const Slide = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: 20rem;
  background-image: linear-gradient(
      rgba(0, 0, 20, 0),
      rgba(0, 0, 20, 0),
      rgba(0, 0, 20, 0),
      rgba(0, 0, 20, 0.75)
    ),
    url("${({ imageUrl }) => imageUrl}");
  background-size: cover;
  background-position: center center;
  filter: brightness(90%);

  @media only screen and (min-width: 640px) {
    height: 30rem;
  }
`;

type PropertyGalleryImageProps = {
  imageUrl: string;
  className?: string;
};

export const PropertyGalleryImage = ({
  imageUrl,
  className,
}: PropertyGalleryImageProps) => {
  return <Slide imageUrl={imageUrl} className={className} />;
};
