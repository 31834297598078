import {
  FormEvent,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import queryString from "query-string";
import { DarkCard } from "components/shared/cards";
import { Text, CardTitle, Subtitle } from "components/shared/typography";
import { TextInput } from "components/shared/inputs";
import { WhiteButton } from "components/shared/buttons";
import { LoadingIcon } from "components/shared/icons";
import { useLazyMixpanelTrack } from "hooks";
import {
  useFindRightmovePropertyMutation,
  useFindScrapesQuery,
} from "store/apis";
import { MixActions } from "lib/mixpanel";
import { RightmoveLogo } from "assets/images/RightmoveLogo";
import GirlBlondeHeartEyes from "assets/images/memojis/girl_blonde_heart_eyes.png";
import { useSelector } from "store";
import { selectRightmoveProperty } from "store/reducers";
import { PropertyScrapeImage } from "components/shared/features/scrapes/PropertyScrapeImage";
import { PropertyGallery } from "components/shared/features";

const SmallRightmoveLogo = styled(RightmoveLogo)`
  display: inline;
  height: 2.25rem;
  transform: translate(0, -0.1875rem);
`;

const Memoji = styled.img`
  margin: 0 auto;
  width: 6rem;
`;

const ScrapesGallery = styled.div``;

type FormData = {
  url: string;
};

export const RightmoveForm = () => {
  const location = useLocation();
  const [formData, setFormData] = useState<FormData>({
    url: "",
  });
  const track = useLazyMixpanelTrack();
  const { data: scrapes } = useFindScrapesQuery(undefined);
  const [findProperty, { isLoading }] = useFindRightmovePropertyMutation();
  const property = useSelector(selectRightmoveProperty);
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
  });

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    track(MixActions.FindRightmoveProperty, {
      ...formData,
    });
    if (!formData.url) {
      // TODO: Tell the user there is no URL...
      return;
    }
    findProperty({ ...formData });
  };

  useEffect(() => {
    const { sourceUrl }: { sourceUrl?: string } = queryString.parse(
      location.search
    );
    if (!sourceUrl) return;
    if (sourceUrl.indexOf("https://www.rightmove.co.uk/properties") !== 0)
      return;
    track(MixActions.UsePropertyScrape, {
      url: sourceUrl,
    });
    findProperty({
      url: sourceUrl,
    });
  }, [location.search]);

  if (property) {
    return (
      <DarkCard className="sm:flex-row sm:items-center sm:justify-between">
        <div className="flex flex-1 flex-col gap-5 text-center items-center">
          <Text textSize="text-5xl" fontWeight="font-semibold">
            You have great taste in houses
          </Text>
          <Subtitle textColor="text-white">
            {formatter.format(property.totalPrice)} - {property.bedrooms} beds
          </Subtitle>
          <div className="flex flex-row gap-5 items-center">
            <p className="text-sm text-gray-400 max-w-xs">
              Scroll down to see how much better off you could be after 3 years
              of owning a house with your friends
            </p>
            <Memoji src={GirlBlondeHeartEyes} />
          </div>
        </div>
        <PropertyGallery property={property} className="flex-1 -mx-6 -mb-12" />
      </DarkCard>
    );
  }

  return (
    <DarkCard className="sm:gap-12 sm:flex-row sm:justify-between">
      <div className="flex flex-1 flex-col gap-10">
        <div className="flex flex-col gap-4">
          <CardTitle>
            <span>
              Found a house on{" "}
              <a
                href="https://www.rightmove.co.uk/property-for-sale.html"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                <SmallRightmoveLogo />
              </a>{" "}
              ?
            </span>
          </CardTitle>
          <p className="text-gray-300 text-center sm:text-left sm:max-w-xl">
            Paste the link below to see how much better off you could be by
            owning a house with your friends.
          </p>
        </div>
        <Form
          property={formData}
          onChange={setFormData}
          loading={isLoading}
          onSubmit={handleSubmit}
        />
      </div>
      {!property && scrapes && scrapes.length > 0 && (
        <div className="flex flex-1 flex-col gap-4">
          <Text>
            Or try with a property recently added by the Housewarming community.
          </Text>
          <ScrapesGallery className="grid grid-cols-2 grid-flow-row gap-4 text-left">
            {scrapes.map((scrape) => {
              const queryParams = queryString.stringify({
                sourceUrl: scrape.sourceUrl,
              });
              return (
                <Link to={`/how-it-works?${queryParams}#rightmove`}>
                  <PropertyScrapeImage key={scrape.id} scrape={scrape} />
                </Link>
              );
            })}
          </ScrapesGallery>
        </div>
      )}
    </DarkCard>
  );
};

type FormProps = {
  property: FormData;
  loading: boolean;
  onChange: Dispatch<SetStateAction<FormData>>;
  onSubmit: (event: FormEvent) => void;
};

const Form = ({ property, onChange, loading, onSubmit }: FormProps) => {
  return (
    <form className="w-full" onSubmit={onSubmit}>
      <div className="flex flex-col gap-4">
        <label htmlFor="email-address" className="sr-only">
          Paste a Rightmove link
        </label>
        <TextInput
          name="url"
          placeholder="https://www.rightmove.co.uk/properties/..."
          className="bg-white/5 border-0"
          textColor="text-white"
          focusBorderColor="focus:border-transparent"
          focusRingColor="focus:ring-transparent"
          value={property.url}
          onChange={(event) =>
            onChange((prevState) => ({
              ...prevState,
              url: event.target.value || "",
            }))
          }
        />
        <WhiteButton
          type="submit"
          borderColor="border-transparent"
          disabled={loading}
        >
          {loading ? <LoadingIcon /> : "Show me how it works"}
        </WhiteButton>
      </div>
    </form>
  );
};
