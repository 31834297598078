import styled from "styled-components";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { TProperty, UUID } from "types";
import { CardTitle, Header, Text } from "components/shared/typography";
import { BedroomCard } from "components/shared/features/calculator/BedroomCard";
import { TCalculatorBedroom } from "types/dto/calculator";
import { PlainCard } from "components/shared/cards";
import { CalculatorOutcome } from "components/shared/features/calculator/CalculatorOutcome";
import { ExpenseCards } from "components/shared/features";
import { GetStartedForm } from "components/shared/features/marketing/GetStartedForm";
import memojiCoupleUrl from "assets/images/memojis/memoji_couple_1.png";
import memojiFriendsUrl from "assets/images/memojis/memoji_two_friends.png";

const MemojiImage = styled.img`
  width: 100%;
  max-width: 20rem;
  margin: 0 auto -1.5rem;
`;

const StyledExpenseCards = styled(ExpenseCards)`
  position: absolute;
  width: 24rem;
  height: auto;
  top: -3.25rem;
  margin-left: -12rem;
  left: 50%;

  @media screen and (min-width: 640px) {
    width: 30rem;
    margin-left: -15rem;
    top: -2rem;
  }
`;

const initialOwnerships: Record<number, number[]> = {
  1: [1],
  2: [0.5, 0.5],
  3: [0.35, 0.35, 0.3],
  4: [0.25, 0.25, 0.25, 0.25],
};

type CalculatorProps = {
  property: TProperty;
};

export const Calculator = ({ property }: CalculatorProps) => {
  const [bedrooms, setBedrooms] = useState<Record<UUID, TCalculatorBedroom>>(
    {}
  );

  useEffect(() => {
    const ownerships: number[] | undefined =
      initialOwnerships[property.bedrooms];
    if (!ownerships) {
      return;
    }
    setBedrooms(
      new Array(property.bedrooms).fill(null).reduce((values, _, index) => {
        const id = uuidv4();
        return {
          ...values,
          [id]: {
            id,
            ordering: index,
            nickname: `Bedroom ${index + 1}`,
            depositPercentage: 0.1, // 10%
            ownership: ownerships[index],
            ownershipFixed: false,
          },
        };
      }, {})
    );
  }, [property.bedrooms]);

  const handleBedroomChange = (bedroom: TCalculatorBedroom) => {
    const difference = bedroom.ownership - bedrooms[bedroom.id].ownership;

    // We either need to find or remove the difference from other bedrooms.
    // Find a non-static bedroom and remove from it there.
    const mutableBedrooms = Object.values(bedrooms).filter(
      ({ id, ownershipFixed }: TCalculatorBedroom) => {
        if (id === bedroom.id) {
          return false;
        }
        return !ownershipFixed;
      }
    );

    let surrogateBedroom: TCalculatorBedroom | undefined;
    if (mutableBedrooms.length) {
      surrogateBedroom = mutableBedrooms[0];
      surrogateBedroom.ownership += -difference;
    }

    setBedrooms((prevState) => {
      const patch = {
        ...prevState,
        [bedroom.id]: {
          ...bedroom,
          ownershipFixed:
            property.bedrooms <= 2 ? false : bedroom.ownershipFixed,
        },
      };
      if (surrogateBedroom) {
        patch[surrogateBedroom.id] = { ...surrogateBedroom };
      }
      return patch;
    });
  };

  if (Object.keys(bedrooms).length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-20">
      <div className="flex flex-col gap-6">
        <div className="text-center flex flex-col gap-4">
          <CardTitle textColor="text-slate-800">Pick your bedroom</CardTitle>
          <Text textColor="text-gray-400">
            Each owner has a bedroom and can own a different percentage of the
            house
          </Text>
        </div>
        <MemojiImage src={memojiCoupleUrl} />
        <div className="flex flex-col flex-wrap gap-4 sm:flex-row sm:justify-center">
          {Object.values(bedrooms)
            .sort((a, b) => a.ordering - b.ordering)
            .map((bedroom) => {
              return (
                <BedroomCard
                  key={bedroom.id}
                  propertyPrice={property.totalPrice}
                  bedroom={bedroom}
                  onChange={handleBedroomChange}
                />
              );
            })}
        </div>
      </div>
      <div className="flex flex-col gap-20 self-center sm:max-w-xl">
        <div className="flex flex-col gap-6 sm:w-full">
          <div className="text-center flex flex-col gap-4">
            <CardTitle textColor="text-slate-800">
              Enjoy living with friends
            </CardTitle>
            <Text textColor="text-gray-400">
              Move in with your friends and start building wealth together
            </Text>
          </div>
          <MemojiImage src={memojiFriendsUrl} />
          <PlainCard
            paddingY="py-12"
            paddingX="px-12"
            className="text-center overflow-visible"
          >
            <div className="flex flex-col gap-2 items-center">
              <div className="relative w-full h-36 sm:h-52">
                <StyledExpenseCards />
              </div>
              <Header>Expense cards</Header>
              <Text textColor="text-gray-400">
                You each get an expense card for household bills like council
                tax and water
              </Text>
            </div>
          </PlainCard>
        </div>
        <div className="flex flex-col gap-6 sm:w-full">
          <div className="text-center flex flex-col gap-4">
            <CardTitle textColor="text-slate-800">
              Sell when you need to
            </CardTitle>
            <Text textColor="text-gray-400">
              You can sell your room when the time comes for you to move on
            </Text>
          </div>
          <CalculatorOutcome
            propertyPrice={property.totalPrice}
            bedrooms={Object.values(bedrooms)}
          />
        </div>
        <div className="flex flex-col gap-10 sm:w-full">
          <div className="text-center flex flex-col gap-4">
            <CardTitle textColor="text-slate-800">
              Plan with your friends today
            </CardTitle>
            <Text textColor="text-gray-400">
              Are you ready to throw your housewarming party?
            </Text>
          </div>
          <div className="w-full sm:pb-24">
            <GetStartedForm
              scrapeId={property.id}
              propertyPrice={property.totalPrice}
              bedroomCount={Object.values(bedrooms).length}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
