import { ReactNode } from "react";
import clsx from "clsx";
import {
  PlainCard,
  PlainCardProps,
} from "components/shared/cards/PlainCard/PlainCard";

type WeatherCardProps = PlainCardProps & {
  children: ReactNode | ReactNode[];
  id?: string;
  className?: string;
};

export const DarkCard = ({
  id,
  children,
  className,
  ...otherProps
}: WeatherCardProps) => {
  return (
    <PlainCard
      {...otherProps}
      id={id}
      backgroundColor="bg-gray-900"
      className={clsx("text-white", className)}
    >
      {children}
    </PlainCard>
  );
};
