type CompoundOptions = {
  years: number;
  growthRate: number;
};

export const calculateMortgagePayment = (
  borrowAmount: number,
  options: CompoundOptions
): number => {
  const borrowedMoney = borrowAmount;
  const lengthOfLoan = options.years * 12;
  const interestReady = options.growthRate / 12;

  const percentage = interestReady;
  const percentagePlusOne = interestReady + 1;
  const exponentiationOperator = percentagePlusOne ** lengthOfLoan;
  const firstDividend = percentage * exponentiationOperator;
  const secondDividend = exponentiationOperator - 1;
  const division = firstDividend / secondDividend;
  return borrowedMoney * division;
};

export const calculateCompoundedPrice = (
  price: number,
  options: CompoundOptions
): number => {
  return price * Math.pow(1 + options.growthRate, options.years);
};

export const calculateInterestPaid = (
  borrowed: number,
  monthlyPayment: number,
  afterNumberOfYears: number,
  options: CompoundOptions
): number => {
  const numberOfPaymentsPerYear = 12;
  const monthlyInterest = options.growthRate / numberOfPaymentsPerYear;
  const months = afterNumberOfYears * 12;

  let principal = borrowed;
  let totalInterestPaid = 0;
  for (let i = 0; i < months; i++) {
    const interestThisMonth = principal * monthlyInterest;
    totalInterestPaid += interestThisMonth;

    const principalPaidThisMonth = monthlyPayment - interestThisMonth;
    principal -= principalPaidThisMonth;
  }

  return totalInterestPaid;
};
