import { forwardRef, ReactNode } from "react";
import classNames from "classnames";
import {
  TailwindBackgroundColor,
  TailwindBackgroundDisabledColor,
  TailwindBackgroundHoverColor,
  TailwindBorderColor,
  TailwindFontWeight,
  TailwindHeight,
  TailwindLayout,
  TailwindPadding,
  TailwindPaddingX,
  TailwindPaddingY,
  TailwindRingColor,
  TailwindRingFocusColor,
  TailwindRounding,
  TailwindShadow,
  TailwindTextColor,
  TailwindTextHoverColor,
  TailwindTextSize,
  TailwindTextTransform,
  TailwindWidth,
} from "types/tailwind";
import { MixActions } from "lib/mixpanel";
import { useLazyMixpanelTrack } from "hooks";

export type GenericButtonProps = {
  id?: string;
  type?: "button" | "submit";
  children?: ReactNode;
  disabled?: boolean;
  leadingIcon?: any;
  trailingIcon?: any;
  onClick?: (event: any) => void;
  rounding?: TailwindRounding;
  width?: TailwindWidth;
  height?: TailwindHeight;
  padding?: TailwindPadding;
  paddingY?: TailwindPaddingY;
  paddingX?: TailwindPaddingX;
  textColor?: TailwindTextColor;
  textHoverColor?: TailwindTextHoverColor;
  fontWeight?: TailwindFontWeight;
  textTransform?: TailwindTextTransform;
  borderColor?: TailwindBorderColor;
  textSize?: TailwindTextSize;
  background?: TailwindBackgroundColor;
  backgroundHover?: TailwindBackgroundHoverColor;
  disabledBackground?: TailwindBackgroundDisabledColor;
  ringColor?: TailwindRingColor;
  ringFocusColor?: TailwindRingFocusColor;
  shadow?: TailwindShadow;
  layout?: TailwindLayout;
  trackAction?: MixActions;
  className?: string;
};

export type ButtonProps = GenericButtonProps & {};

export const Button = forwardRef(
  (
    {
      width = "w-full",
      layout = "inline-flex",
      rounding = "rounded-lg",
      textColor = "text-white",
      textHoverColor = "",
      borderColor = "border-transparent",
      textSize = "text-base",
      fontWeight = "font-medium",
      height = "",
      type = "button",
      padding = "",
      paddingY = "py-3",
      paddingX = "px-6",
      background = "bg-blue-400",
      backgroundHover = "hover:bg-blue-500",
      disabledBackground = "disabled:bg-slate-300",
      ringColor = "ring-transparent",
      ringFocusColor = "focus:ring-blue-500",
      textTransform = "",
      shadow = "",
      children,
      id,
      disabled = false,
      onClick,
      trackAction,
      leadingIcon: LeadingIcon,
      trailingIcon: TrailingIcon,
      className,
      ...otherProps
    }: ButtonProps,
    ref: any
  ) => {
    const track = useLazyMixpanelTrack();
    const classes = classNames(
      "items-center justify-center border whitespace-nowrap ring-2 focus:ring-2 focus:ring-offset-2",
      layout,
      width,
      height,
      padding,
      rounding,
      textColor,
      textHoverColor,
      textSize,
      fontWeight,
      borderColor,
      background,
      backgroundHover,
      disabledBackground,
      ringColor,
      ringFocusColor,
      padding,
      paddingX,
      paddingY,
      shadow,
      textTransform,
      className
    );

    const handleClick = (event: any) => {
      onClick && onClick(event);
      if (trackAction) {
        track(trackAction);
      }
    };

    return (
      <button
        {...otherProps}
        ref={ref}
        type={type}
        id={id}
        className={classes}
        onClick={handleClick}
        disabled={disabled}
      >
        {LeadingIcon && (
          <LeadingIcon
            className={classNames("h-5 w-5", !!children && "-ml-1 mr-2")}
          />
        )}
        {children}
        {TrailingIcon && (
          <TrailingIcon
            className={classNames("h-5 w-5", !!children && "ml-2 -mr-1")}
          />
        )}
      </button>
    );
  }
);
