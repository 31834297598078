import styled from "styled-components";
import { PlainCard } from "components/shared/cards";
import { TCalculatorBedroom } from "types";
import { Header, Text } from "components/shared/typography";
import { calculateMortgagePayment } from "lib/utils/mortgage";
import { OwnershipSlider } from "components/shared/features/calculator/OwnershipSlider";

const Price = styled(Text)`
  font-size: 3rem;
  line-height: 1em;
  font-weight: 600;
`;

type BedroomCardProps = {
  propertyPrice: number;
  bedroom: TCalculatorBedroom;
  onChange: (value: TCalculatorBedroom) => void;
};

export const BedroomCard = ({
  propertyPrice,
  bedroom,
  onChange,
}: BedroomCardProps) => {
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
  });
  const bedroomCost = propertyPrice * bedroom.ownership;
  const depositAmount = bedroomCost * bedroom.depositPercentage;

  return (
    <PlainCard paddingY="py-12" className="text-center sm:min-w-[23rem]">
      <div className="flex flex-col gap-2 text-gray-900">
        <Header>{bedroom.nickname}</Header>
        <Price>{formatter.format(bedroomCost)}</Price>
      </div>
      <div>
        <OwnershipSlider
          value={bedroom.ownership}
          onChange={(value) =>
            onChange({ ...bedroom, ownership: value, ownershipFixed: true })
          }
        />
      </div>
      <div className="flex flex-row justify-around text-gray-900">
        <div className="flex flex-col">
          <Header>{formatter.format(depositAmount)}</Header>
          <Text textSize="text-sm" textColor="text-gray-400">
            Deposit
          </Text>
        </div>
        <div className="flex flex-col">
          <Header>
            {formatter.format(
              calculateMortgagePayment(bedroomCost - depositAmount, {
                years: 25,
                growthRate: 0.045, // 4.5%
              })
            )}
            /month
          </Header>
          <Text textSize="text-sm" textColor="text-gray-400">
            Mortgage payment
          </Text>
        </div>
      </div>
    </PlainCard>
  );
};
