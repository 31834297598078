import styled from "styled-components";
import { MobileApp } from "components/shared/features/marketing/MobileApp";

const MobileAppContainer = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: -8rem;
  z-index: 0;
  width: 509px;
  height: 800px;

  @media only screen and (min-width: 1024px) {
    display: block;
  }
`;

export const HeroTitle = () => {
  return (
    <div className="relative">
      <div className="relative z-10 text-center lg:text-left">
        <h1 className="text-6xl lg:text-[7rem] lg:max-w-[50rem] font-bold text-white">
          Your first step on the property ladder
        </h1>
        <p className="mt-6 lg:max-w-2xl text-xl text-white font-medium">
          We help “Generation Rent” get on the property ladder by making it
          possible to buy with your partner, friends or flatmates.
        </p>
      </div>
      <MobileAppContainer>
        <MobileApp />
      </MobileAppContainer>
    </div>
  );
};
