import clsx from "clsx";
import { TProperty } from "types";
import styled from "styled-components";
import { PlainCard } from "components/shared/cards/PlainCard/PlainCard";
import { Text } from "components/shared/typography";

const Description = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
`;

const Slide = styled.div<{ imageUrl: string }>`
  width: 100%;
  height: 12.5rem;
  background-image: linear-gradient(
      rgba(0, 0, 20, 0),
      rgba(0, 0, 20, 0.25),
      rgba(0, 0, 20, 0.5),
      rgba(0, 0, 20, 0.5)
    ),
    url("${({ imageUrl }) => imageUrl}");
  background-size: cover;
  background-position: center center;
  filter: brightness(90%);

  @media only screen and (min-width: 640px) {
    height: 12.5rem;
  }
`;

type PropertyScrapeImageProps = {
  scrape: TProperty;
  className?: string;
  onClick?: () => void;
};

export const PropertyScrapeImage = ({
  scrape,
  className,
  onClick,
}: PropertyScrapeImageProps) => {
  const { propertyType, images, address, totalPrice, bedrooms } = scrape;
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
  });

  return (
    <PlainCard
      paddingY=""
      paddingX=""
      className={clsx("relative", className)}
      onClick={onClick}
    >
      {images.length !== 0 ? <Slide imageUrl={images[0]} /> : null}
      <Description className="text-white">
        <Text className="font-medium">{formatter.format(totalPrice)}</Text>
        <Text textSize="text-sm">
          {bedrooms} bed {propertyType}
        </Text>
        <Text textSize="text-sm">{address}</Text>
      </Description>
    </PlainCard>
  );
};
