import { FormEvent, useState } from "react";
import { useLazyMixpanelTrack } from "hooks";
import { EmailInput } from "components/shared/inputs";
import { WhiteButton } from "components/shared/buttons";
import { MixActions } from "lib/mixpanel";
import { useSubscribeMutation } from "store/apis";
import { CardTitle, Header, Title } from "components/shared/typography";
import { IconWithConfetti, LoadingIcon } from "components/shared/icons";
import { DarkCard } from "components/shared/cards";

export const LaunchForm = () => {
  const track = useLazyMixpanelTrack();
  const [email, setEmail] = useState<string>("");
  const [subscribe, { isLoading, isSuccess }] = useSubscribeMutation();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    track(MixActions.LaunchSubscribe, {
      email,
    });
    subscribe({ email });
  };

  return (
    <div className="pt-16 sm:py-24">
      <div className="mx-auto max-w-7xl">
        <DarkCard className="sm:flex-row sm:items-center">
          <CardTitle id="early-access">
            Get notified when we’re launching.
          </CardTitle>

          {isSuccess ? (
            <div className="w-full max-w-md mx-auto text-center">
              <IconWithConfetti src="/images/icons/party.svg" width="w-14" />
              <Header>Thank you for your interest!</Header>
            </div>
          ) : (
            <form className="w-full max-w-md mx-auto" onSubmit={handleSubmit}>
              <div className="flex flex-col gap-4 lg:flex-row">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <EmailInput
                  name="email"
                  placeholder="Enter your email"
                  className="bg-white/5 border-0"
                  textColor="text-white"
                  focusBorderColor="focus:border-transparent"
                  focusRingColor="focus:ring-transparent"
                  onChange={(event) => setEmail(event.target.value)}
                />

                <WhiteButton
                  type="submit"
                  borderColor="border-transparent"
                  className="lg:w-min"
                  disabled={!email || isLoading}
                >
                  {isLoading ? <LoadingIcon /> : "Notify me"}
                </WhiteButton>
              </div>
              <p className="mt-4 text-sm leading-6 text-gray-300">
                We care about your data. Read our{" "}
                <a href="/privacy-policy" className="font-semibold text-white">
                  privacy&nbsp;policy
                </a>
                .
              </p>
            </form>
          )}
        </DarkCard>
      </div>
    </div>
  );
};
