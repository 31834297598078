import tailwindColors from "tailwindcss/colors";

const colors = {
  ...tailwindColors,
  salmon: {
    "300": "#F89191",
    "600": "#FF6B6B",
  },
};

const palette = {
  primary: colors.salmon["600"],
};

export { colors, palette };
