import classNames from "classnames";
import {
  TailwindBorderColor,
  TailwindBorderFocusColor,
  TailwindHeight,
  TailwindPadding,
  TailwindPaddingX,
  TailwindPaddingY,
  TailwindPlaceholderColor,
  TailwindRingFocusColor,
  TailwindRounding,
  TailwindTextColor,
  TailwindTextSize,
  TailwindWidth,
} from "types/tailwind";

export type GenericInputProps = {
  name: string;
  autoFocus?: boolean;
  value?: any;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  autoComplete?: string;
  onChange?: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  onClick?: (event: any) => void;
  rounding?: TailwindRounding;
  width?: TailwindWidth;
  height?: TailwindHeight;
  padding?: TailwindPadding;
  paddingY?: TailwindPaddingY;
  paddingX?: TailwindPaddingX;
  textColor?: TailwindTextColor;
  borderColor?: TailwindBorderColor;
  placeholderColor?: TailwindPlaceholderColor;
  focusRingColor?: TailwindRingFocusColor;
  focusBorderColor?: TailwindBorderFocusColor;
  textSize?: TailwindTextSize;
  className?: string;
};

export type InputProps = GenericInputProps & {
  type: string;
};

export const Input = ({
  width = "w-full",
  rounding = "rounded-md",
  textColor = "text-gray-900",
  borderColor = "border-gray-200",
  placeholderColor = "placeholder-gray-400",
  focusRingColor = "focus:ring-salmon-600",
  focusBorderColor = "focus:border-salmon-600",
  textSize = "text-base",
  height = "",
  padding = "p-3",
  paddingY = "",
  paddingX = "",
  className,
  ...otherProps
}: InputProps) => {
  const classes = classNames(
    "appearance-none relative block border focus:outline-none",
    width,
    height,
    padding,
    rounding,
    textColor,
    borderColor,
    placeholderColor,
    focusRingColor,
    focusBorderColor,
    className
  );
  return <input className={classes} {...otherProps} />;
};
