import { ReactNode } from "react";
import { Container } from "components/shared/layouts/Container";

type PageContainerProps<TChildren = ReactNode> = {
  className?: string;
  children: TChildren | Array<TChildren>;
};

export const PageContainer = ({ className, children }: PageContainerProps) => {
  return (
    <Container className={className} paddingX="px-4">
      {children}
    </Container>
  );
};
