import styled from "styled-components";
import { colors } from "lib/colors";

const Parent = styled.div`
  display: flex;
  flex-direction: row;
  height: 1rem;
  align-items: center;
  text-transform: uppercase;
  color: ${colors.slate["400"]};
  font-weight: 300;
  font-size: 0.875rem;

  span {
    margin: 0 0.25rem;
  }
`;

const Line = styled.div`
  content: "";
  display: block;
  height: 1px;
  flex: 1;
  background-color: ${colors.slate["200"]};
`;

type SeparatorProps = {
  label?: string;
};

export const Separator = ({ label = "" }: SeparatorProps) => {
  return (
    <Parent>
      <Line />
      {label && <span>{label}</span>}
      {label && <Line />}
    </Parent>
  );
};
