import { Button, GenericButtonProps } from "components/shared/buttons";

export type PrimaryButtonProps = GenericButtonProps & {};

export const PrimaryButton = ({ ...otherProps }: PrimaryButtonProps) => {
  return (
    <Button
      background="bg-salmon-600"
      textHoverColor="hover:text-white"
      backgroundHover="hover:bg-salmon-500"
      ringFocusColor="focus:ring-salmon-600"
      {...otherProps}
    />
  );
};
