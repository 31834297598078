import {
  FindRightmovePropertyRequest,
  FindRightmovePropertyResponse,
  FindScrapesResponse,
} from "types/dto";
import { baseApi } from "../base.api";

export const rightmoveApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    findScrapes: builder.query<FindScrapesResponse["data"], undefined>({
      query: () => ({
        url: "providers/rightmove/scrapes",
        method: "GET",
      }),
      transformResponse: (response: FindScrapesResponse) => response.data,
    }),
    findRightmoveProperty: builder.mutation<
      FindRightmovePropertyResponse["data"],
      FindRightmovePropertyRequest
    >({
      query: (body) => ({
        url: "providers/rightmove/scrapes",
        method: "POST",
        body,
      }),
      transformResponse: (response: FindRightmovePropertyResponse) =>
        response.data,
    }),
  }),
});

export const { useFindScrapesQuery, useFindRightmovePropertyMutation } =
  rightmoveApi;
