import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { HousewarmingLogo, PageContainer } from "components/shared";
import { colors } from "lib/colors";

const Layout = styled.div`
  display: flex;
  flex-direction: row;
`;

const AuthPageContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;
  margin: 0 auto;

  @media only screen and (min-width: 768px) {
    max-width: 35rem;
    padding: 2.5rem 5rem;
  }
`;

const LogoContainer = styled.div``;

const MockBackground = styled.div`
  display: none;
  flex: 1;
  min-height: 100vh;
  background-color: ${colors.slate["100"]};

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

export const AuthLayout = () => {
  return (
    <Layout>
      <AuthPageContainer>
        <LogoContainer>
          <HousewarmingLogo />
        </LogoContainer>
        <Outlet />
      </AuthPageContainer>
      <MockBackground />
    </Layout>
  );
};
