type RightmoveLogoProps = {
  className?: string;
};

export const RightmoveLogo = ({ className }: RightmoveLogoProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="51.5 28.8 746 151.8"
      className={className}
    >
      <path
        fill="#00deb6"
        d="M773.4,112.2h12.8l0-39.2L751,43.4L715.8,73l0,39.2h12.8l22.5-19L773.4,112.2z M787.7,123.4h-18.4L751,107.9
	l-18.4,15.5h-18.4c-2.6,0-5-1-6.8-2.8c-1.8-1.8-2.8-4.3-2.8-6.9l0.1-41.4c0-2.6,1-5,2.8-6.8l0.4-0.4L751,28.8l43.6,36.7
	c1.8,1.8,2.8,4.2,2.8,6.8l0.1,41.4c0,2.6-1,5-2.8,6.9S790.3,123.4,787.7,123.4"
      />
      <path
        fill="currentColor"
        d="M432,82.8c-4.4,0-8.6,0.8-12.7,2.4c-4.1,1.6-7.5,3.9-10.3,6.8c-0.8-1-1.8-2.1-3-3.2c-1.2-1.1-2.6-2.1-4.3-3
	c-1.6-0.9-3.5-1.6-5.6-2.2c-2.2-0.6-4.6-0.8-7.3-0.8c-4.5,0-8.4,0.8-11.7,2.5c-3.3,1.7-6.1,3.8-8.2,6.4l-0.7-7.3h-20.2v66.2h21.1
	v-35.6c0-4.2,1.2-7.7,3.7-10.4c2.5-2.7,5.7-4.1,9.8-4.1c3.6,0,6.3,1.1,8.1,3.2c1.8,2.2,2.7,5.2,2.7,9.2v37.7h20.4v-35.6
	c0-4.2,1.2-7.7,3.7-10.4c2.4-2.7,5.7-4.1,9.8-4.1c3.6,0,6.3,1.1,8.1,3.2c1.8,2.2,2.7,5.2,2.7,9.2v37.7h21.1v-40.9
	c0-8.3-2.3-14.8-7-19.6C447.5,85.2,440.8,82.8,432,82.8 M518.5,123.9c-0.7,2.1-1.7,4-3.2,5.7c-1.4,1.7-3.2,3-5.2,4
	c-2.1,1-4.4,1.5-7,1.5c-2.6,0-5-0.5-7.1-1.5c-2.1-1-3.8-2.3-5.2-4c-1.4-1.7-2.4-3.6-3.2-5.7c-0.7-2.1-1.1-4.3-1.1-6.6
	s0.4-4.4,1.1-6.6c0.7-2.1,1.8-4,3.2-5.6c1.4-1.6,3.1-3,5.2-4c2.1-1,4.5-1.5,7.1-1.5c2.6,0,5,0.5,7,1.5c2.1,1,3.8,2.3,5.2,4
	c1.4,1.6,2.5,3.5,3.2,5.6c0.7,2.1,1,4.3,1,6.6S519.2,121.8,518.5,123.9 M529.7,92.4c-3.2-3.2-7.1-5.7-11.6-7.6
	c-4.5-1.8-9.5-2.8-15-2.8s-10.5,0.9-15,2.8c-4.5,1.8-8.4,4.4-11.7,7.6c-3.2,3.2-5.7,6.9-7.6,11.2c-1.8,4.3-2.7,8.9-2.7,13.8
	c0,4.9,0.9,9.5,2.7,13.8c1.8,4.3,4.3,8,7.6,11.2c3.2,3.2,7.1,5.7,11.7,7.6c4.5,1.9,9.6,2.8,15,2.8s10.5-0.9,15-2.8
	c4.5-1.9,8.4-4.4,11.6-7.6c3.2-3.2,5.7-6.9,7.5-11.2c1.8-4.3,2.7-8.9,2.7-13.8c0-4.9-0.9-9.5-2.7-13.8
	C535.4,99.3,532.9,95.6,529.7,92.4 M592.5,84.3l-15.7,43.5l-16.3-43.5H537l29,66.2H586l29.5-66.2H592.5z M633.1,110.4
	c0-1.4,0.3-2.8,1-4.3c0.6-1.5,1.6-2.8,2.8-4c1.2-1.2,2.8-2.2,4.6-3c1.9-0.8,4-1.2,6.5-1.2c2.4,0,4.5,0.4,6.3,1.2
	c1.8,0.8,3.2,1.8,4.4,3c1.2,1.2,2.1,2.6,2.6,4c0.6,1.5,0.9,2.9,0.9,4.3H633.1z M682.1,120c0-5.6-0.7-10.7-2.2-15.3
	c-1.5-4.7-3.6-8.7-6.5-12c-2.9-3.3-6.4-6-10.6-7.8c-4.2-1.9-9.1-2.8-14.7-2.8c-5.6,0-10.6,1-15,2.9c-4.4,1.9-8.1,4.5-11.1,7.7
	c-3,3.2-5.4,6.9-7,11.2c-1.6,4.3-2.4,8.7-2.4,13.3c0,5.3,0.9,10.1,2.6,14.5c1.7,4.4,4.2,8.1,7.5,11.2c3.3,3.1,7.3,5.5,12,7.3
	c4.7,1.7,10,2.6,15.8,2.6c4.4,0,8.3-0.4,11.7-1.2c3.4-0.8,6.4-1.7,8.9-2.8c2.5-1,4.5-2.1,6.1-3.2c1.6-1.1,2.7-1.9,3.5-2.3l-8.7-12.8
	c-1.6,1.1-4.1,2.5-7.5,4s-7.9,2.3-13.3,2.3c-5.6,0-9.9-1.3-13.1-4c-3.2-2.7-4.8-5.8-4.9-9.4h49V120z M331.1,135.3
	c-1.6,0.7-3.4,1.1-5.6,1.1c-2.8,0-4.9-0.9-6.2-2.6c-1.3-1.7-2-4.2-2-7.4v-26.1h17.7v-16h-17.7v-20h-21.1v20h-10.9v16h10.9v25
	c0,4.1,0.4,7.9,1.3,11.3c0.9,3.4,2.3,6.3,4.4,8.7c2.1,2.4,4.7,4.3,8.1,5.6c3.3,1.4,7.4,2,12.3,2c7,0,13.6-2.1,19.7-6.2l-6.7-13.7
	C334,133.9,332.6,134.7,331.1,135.3 M91.7,83c-4.9,0-8.9,1-12,3.1c-3.1,2.1-5.5,4.2-7.3,6.5l-0.7-8.3H51.5v66.2h21.1v-32.8
	c0-5.3,1.5-9.3,4.6-12.2c3.1-2.9,7.1-4.3,12.1-4.3c2.4,0,4.5,0.2,6.3,0.7l3.4-18c-0.9-0.3-1.7-0.5-2.6-0.6
	C95.1,83.1,93.6,83,91.7,83 M104.6,150.5h21.1V84.3h-21.1V150.5z M182.1,121c0,4.3-1.2,7.5-3.5,9.5c-2.3,2-5.7,3-10,3
	c-4.6,0-8.1-1.4-10.5-4.2c-2.4-2.8-3.6-6.6-3.6-11.4c0-2.4,0.4-4.8,1.1-7c0.7-2.2,1.9-4.1,3.4-5.8c1.5-1.6,3.4-3,5.6-4
	c2.2-1,4.9-1.5,7.9-1.5c2.2,0,4.1,0.1,5.4,0.4c1.4,0.2,2.7,0.6,4.1,1.1V121z M172.9,82.9c-6,0-11.4,0.8-16.2,2.5
	c-4.8,1.7-9,4.1-12.5,7.3c-3.5,3.2-6.2,6.9-8.1,11.3c-1.9,4.4-2.9,9.2-2.9,14.6c0,4.6,0.7,8.8,2.1,12.7c1.4,3.9,3.4,7.2,6.1,10
	c2.6,2.8,5.9,5,9.8,6.6c3.9,1.6,8.2,2.3,13.1,2.3c7.3,0,13.2-1.8,17.6-5.5v4.7c0,4.9-1.4,8.6-4.1,11c-2.7,2.4-6.5,3.7-11.3,3.7
	c-3.7,0-7.1-0.4-10.2-1.3c-3.1-0.9-6-2.1-8.7-3.7l-8.5,14.4c3.8,2.4,8,4.2,12.6,5.4c4.6,1.1,10,1.7,16.1,1.7
	c10.9,0,19.4-2.6,25.7-7.8c6.3-5.2,9.4-13.3,9.4-24.2V87.8c-4.1-1.4-8.7-2.6-13.9-3.5C184,83.4,178.6,82.9,172.9,82.9 M255.2,82.8
	c-3.8,0-7.6,0.7-11.3,2c-3.7,1.4-6.7,3.3-8.9,5.9V56.4h-21v94.2h21.1v-35.6c0-2,0.3-3.8,0.9-5.6c0.6-1.7,1.5-3.3,2.6-4.6
	c1.2-1.3,2.6-2.4,4.4-3.2c1.8-0.8,3.8-1.2,6-1.2c3.4,0,6.2,1.1,8.2,3.2c2.1,2.2,3.1,5.2,3.1,9v37.8h21v-40.9c0-8.8-2.4-15.5-7.1-20
	C269.4,85,263.1,82.8,255.2,82.8 M123,58.5c-1.1-0.9-2.3-1.6-3.6-2c-1.4-0.5-2.8-0.7-4.2-0.7c-1.4,0-2.9,0.2-4.2,0.7
	c-1.4,0.5-2.6,1.1-3.7,2c-1.1,0.9-1.9,2-2.6,3.3c-0.6,1.3-1,2.9-1,4.6c0,1.7,0.3,3.2,1,4.5c0.6,1.3,1.5,2.4,2.6,3.3
	c1.1,0.9,2.3,1.6,3.7,2c1.4,0.4,2.8,0.7,4.2,0.7c1.4,0,2.9-0.2,4.2-0.7c1.4-0.4,2.6-1.1,3.6-2c1.1-0.9,1.9-2,2.5-3.3
	c0.6-1.3,1-2.8,1-4.5c0-1.7-0.3-3.2-1-4.6C124.9,60.5,124.1,59.3,123,58.5"
      />
    </svg>
  );
};
