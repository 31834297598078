import { configureStore } from "@reduxjs/toolkit";
import reducer from "store/reducer";
import { baseApi } from "store/base.api";

const createStore = (initialState = {}) => {
  return configureStore({
    reducer,
    devTools: process.env.NODE_ENV === "development",
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([baseApi.middleware]),
  });
};

export default createStore;
